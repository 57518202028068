/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import '~ngx-toastr/toastr';

body {
    background-color: #f4f4f4;
}

.white {
    color: #fff;
  }
  .box {
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ebebeb;
  }
  body {
    background-color: #f4f4f4;
  }
  .navbar {
    margin-bottom: 20px;
  }
  .right {
    float: right;
    display: flex;
    justify-content: flex-end;
  }
  .puzzle-box {
    background-color: #fff;
    z-index: 9999;
    top: 0px;
    left: 0px;
  }
  .done {
    background-color: #a8f8b0;
  }
  .open {
    background-color: #fff5dc;
  }
  @media (min-width: 600px) {
    .padding-box {
      padding: 30px 68px;
    }
  }
  @media (max-width: 600px) {
    .padding-box {
      padding: 15px;
    }
  }
  .popup {
    width: 100vw;
    height: 100vh;
    top: 0;
    padding-top: 30px;
    left: 0;
    z-index: 99;
    position: fixed;
    background-color: rgba(0,0,0,0.75);
    overflow: scroll;
  }
  .popup > span.fa-stack {
    z-index: 30;
    position: fixed;
    top: 30px;
    right: 30px;
    color: #000;
  }
  > .popup-container {
    width: 95%;
    height: 95%;
    overflow: scroll;
    position: relative;
    background-color: #fff;
  }
  @media (min-width: 600px) {
    .main-box {
      background: #fff 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 15px rgba(0,0,0,0.161);
      border-radius: 10px;
      opacity: 1;
    }
  }
  @media (max-width: 600px) {
    .main-box {
      opacity: 1;
    }
  }
  @media (min-width: 600px) {
    .main-box {
      padding: 20px;
      margin-top: 30px;
    }
  }
  @media (max-width: 600px) {
    .main-box {
      padding: 15px;
      margin-top: 30px;
    }
  }

  .pointer {
    cursor: pointer;
  }
  
  .inline-block {
    display: inline-block;
}